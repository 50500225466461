import React from "react";

import styled from "styled-components";

import { H2, P } from "components/Typography";
import { MainContentMaxWidth, MedMaxWidth, SmallMaxWidth } from "components/Layouts/MaxWidth";
import { BackgroundImage, FlexParent } from "components/Layouts/Utils";
import FeaturedTitle from "components/Blog/FeaturedHeader";
import { withPrefix } from "gatsby";

import { data } from "data/expansion-pack/cms"

const Date = ({
  date, username, platform, filename, link, text, revealed, i
}) => {
  return (
    <DateLink
      className={revealed ? "active" : "inactive"}
      href={link}
      target="_blank"
      rel="noopener noreferrer"
    >
      <DateEl>{date}</DateEl>

      <div className="relative">
        <Figure>
          {filename && filename != "" && (<Filename
            className="filename"
            src={withPrefix(`/images/clash-of-inventors/calendar/${filename}`)}
          />)}

          {revealed && (
            <Check
              className="check"
              src={withPrefix(`/images/clash-of-inventors/check.svg`)}
            />
          )}
        </Figure>

        {!revealed && (
          <Platform>
            {platform !== "" && <img
              style={{
                width: "18px",
                height: "auto",
                marginRight: "8px",
              }}
              src={withPrefix(
                platform.toUpperCase() == "TWITCH"
                  ? "/images/clash-of-inventors/twitch-icon.png"
                  : "/images/clash-of-inventors/yt-icon.png"
              )}
            />}
            {username}
          </Platform>
        )}
        <Text><span dangerouslySetInnerHTML={{__html:text}} /></Text>
      </div>
    </DateLink>
  );
}


const Calendar = () => {
  return (
    <Section>
      <BackgroundImage
        src={withPrefix(
          "/images/clash-of-inventors/release-calendar-bg@2x.jpg"
        )}
      />
      <MainContentMaxWidth>
        <FlexParent>
          <Logo
            width={776}
            height={470}
            loading="lazy"
            className="logo"
            alt="Clash of Inventors"
            src={withPrefix("/images/clash-of-inventors/clash-small-logo.png")}
          />
        </FlexParent>

        <div className="title-parent">
          <FeaturedTitle>
            <Blur>{data.calendar.title}</Blur>
          </FeaturedTitle>
        </div>

        <MobileTitle>
          <H2 size="2.2rem">
            <Blur>{data.calendar.title}</Blur>
          </H2>
        </MobileTitle>

        <MedMaxWidth>
          <br />
          <P>
            <span
              dangerouslySetInnerHTML={{ __html: data.calendar.textHTML }}
            />
          </P>
        </MedMaxWidth>

        <CalendarGroup>
          {data.calendar.dates.map((date, i) => (
            <Date {...date} i={i} key={i} />
          ))}
        </CalendarGroup>
      </MainContentMaxWidth>
    </Section>
  );
}

const Section = styled.div`
  position: relative;
  padding: 4rem 0;
  overflow: hidden;

  ${MedMaxWidth} {
    padding: 0 4rem;

    @media (max-width: 700px) {
      padding: 0rem;
    }
  }

  @media (max-width: 700px) {
    ${BackgroundImage}{display: none}
    .title-parent{display: none}
  }
`

const MobileTitle = styled.div`
  width: 100%;
  text-align: center;
  position: relative;
  margin-bottom: 2rem;
  display: none;

  &::after {
    width: 100%;
    top: 50%;
    left: 0;
    content: " ";
    position: absolute;
    border-bottom: 1px solid #4d3c7b;
  }

  @media (max-width: 700px) {
    display: block;
  }
`;

const Blur = styled.span`
  color: white;
  text-shadow: 0px 0px 26px #215ae3, 0px 0px 26px #215ae3;

  @media (max-width: 700px) {
    background: var(--background);
    padding: 0 1.5rem;
    position: relative;
    z-index: 2;
  }
  
`;

const Logo = styled.img`
  max-width: 19rem;
  margin: auto;
  text-align: center;
  width: 100%;
  margin-top:-2rem;
  margin-bottom:-3rem;

  @media (max-width: 700px) {
    max-width: 20rem;
    margin-bottom: 0%;
  }
  `


const CalendarGroup = styled.div`
  display: flex; flex-wrap: wrap;
  grid-gap: 1rem;
  max-width: 1000px;
  margin: 3rem auto;
`
const DateLink = styled.a`
  --borderColor: #4d3c7b;
  --headerBgColor: #0c061e;
  --bgColor: #170d30;


  text-align: center;
  transition: 0.3s ease-out border-color;
  border: 1px solid var(--borderColor);
  width: 19%;
  flex-grow: 1;
  color: white;
  background: var(--bgColor);



  &:hover {
    --borderColor: var(--lilac);
  }
  
  &.active {
    --bgColor: #231445;
    --headerBgColor: #231445;
  }

  @media (max-width: 800px) {
    width: 33%;
  }


  .relative {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height:70%;
  }

`;


const Text = styled.div`
  font-family: var(--font);
  color: var(--lilac);
  font-size: 16px;
  padding: 1rem;
  margin-bottom: 0.25rem;
`;

const DateEl = styled.div`
  padding: 10px;
  font-family: var(--condensedFont);
  font-size: 20px;
  width: 100%;
  background-color: var(--headerBgColor);
  color: white;
  transition: 0.3s ease-out border-color;
  border-bottom: 1px solid var(--borderColor);
`;
const Platform = styled.div`
    font-family: var(--condensedFont);
    color: white;
    font-weight: bold;
    font-size: 16px;
    letter-spacing" -0.01em;
`;
const Figure = styled.figure`
  width: 70%;
  margin: auto;
  margin-top: 10%;
  margin-bottom: 10%;
`;

const Check = styled.img`
  position: absolute;
  top: 0%;
  right: 15%;
  width: 25%;
`;
const Filename = styled.img`
  width: 100%;
`

export default Calendar