import React from "react"
import styled from "styled-components"
import { H2, P } from "components/Typography"
import { ButtonLink }  from "components/Button"
import { SmallMaxWidth } from "components/Layouts/MaxWidth"
import { withPrefix } from "gatsby"
import { BackgroundImage } from "components/Layouts/Utils"

import { data } from "data/expansion-pack/cms";

const Expansion = ({
  title, text, image,  background
}) => {
  return (
    <Section>
      <BackgroundImage src={background.url} />

      <div className="cards">
        <img loading="lazy" width={1759} height={1759} alt="" src={image.url} />
      </div>

      <div className="text-block">
        <SmallMaxWidth>
          <img
            width={776}
            height={470}
            loading="lazy"
            className="logo"
            alt="Clash of Inventors"
            src={withPrefix("/images/clash-of-inventors/clash-small-logo.png")}
          />
          <H2
            style={{ fontSize: "2.2rem", marginBottom: "1rem" }}
            color="white"
          >
            {title}
          </H2>
          <P>{text}</P>
          <ButtonLink
            href={data.cardsLink}
            as="a"
            bgColor={"#0066FF"}
            color={"white"}
          >
            View All Cards
          </ButtonLink>
        </SmallMaxWidth>
      </div>
    </Section>
  );
}

const Section = styled.div`
  padding: 24rem 4rem;
  position: relative;
  overflow: hidden;

  text-align: center;

  ${SmallMaxWidth} {
    padding: 3rem;
  }

  .logo {
    width: 100%;
    margin-top: -2rem;
    margin-bottom: -1rem;
  }

  .cards {
    display: block;
    width: 56rem;
    margin: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .cards img {
    width: 100%;
  }

  @media (max-width: 700px) {
    padding: 24rem 0rem;
  }
`;

export default Expansion
