import React from "react"

import styled from "styled-components";

import FeaturedHeader from "components/Blog/FeaturedHeader";
import { MainContentMaxWidth } from "components/Layouts/MaxWidth";
import { H2, P, CheckMark } from "components/Typography";
import { withPrefix } from "gatsby";


const NewTrait = ({
  title, subtitle, textHTML, points, video
}) => {
  return (
    <Section>
      <VideoGroup>
        <div>
          <video
            src={
              "https://56utils.s3.ca-central-1.amazonaws.com/clash-of-inventors/dash.mp4"
            }
            autoPlay
            muted
            loop
            playsInline
          />
        </div>
      </VideoGroup>

      <MainContentMaxWidth style={{
        zIndex: "6",
        position: "relative"

      }}>
        {/* <FeaturedHeader>
          NEW TRAIT: <span style={{ color: "white" }}>DASH</span>
        </FeaturedHeader> */}

        <TextGroup>
          <img 
          loading="lazy"
          width={303}
          height={417}
          src={withPrefix("/images/traits/dash.png")} alt="Dash" />
          
          <H2
            style={{ fontSize: "3rem", marginBottom: "2rem" }}
            color="white"
          >
            {subtitle}
          </H2>
          <P>
            <span dangerouslySetInnerHTML={{ __html: textHTML }} />
          </P>
          <br />
          {points.map((point, i) => (
            <CheckMark key={i}>{point}</CheckMark>
          ))}
        </TextGroup>
      </MainContentMaxWidth>
    </Section>
  );
}

const Section = styled.div`
  position: relative;
  overflow: hidden;

  ${MainContentMaxWidth} {
    padding: 6rem 0;

    @media (max-width: 750px) {
      padding: 0rem;
    }
  }

  @media (max-width: 750px) {
    ${styled(FeaturedHeader)} {
      margin-top: -5rem;
    }
  }
`;

const TextGroup = styled.div`
  max-width: 32rem;
  padding: 4rem;
  position: relative;
  z-index: 6;

  img {
    max-width: 6rem;
    margin-bottom: 2rem;
  }

  @media (max-width: 750px) {
    padding: 2rem;
    margin-top: 25rem;
    max-width: 100%;

    &::before {
      content: " ";
      position: absolute;
      top: -10rem;
      left: 0;
      z-index: -1;
      background: linear-gradient(
        to bottom,
        rgba(12, 6, 30, 0) 0%,
        var(--background) 100%
      );
      width: 200%;
      height: 20rem;
    }
  }
`;

const VideoGroup = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  overflow: hidden;

  div {
    position: relative;
    width: 100%;
    margin-left: auto;
    height: 100%;
    overflow: hidden;
    max-width: 90rem;

    &::before {
      width: 50%;
      content: " ";
      height: 100%;
      z-index: 4;
      top: 0;
      position: absolute;
      background: linear-gradient(
        to left,
        rgba(12, 6, 30, 0) 0%,
        var(--background) 100%
      );
    }

    &::after {
      right: 0;
      transform: rotate(180deg);
    }
  }

  @media (max-width: 750px) {
    div::after {display: none;}
    div::before {
      width: 100%;
      opacity: 1;
      background: linear-gradient(
        to bottom,
        rgba(12, 6, 30, 0) 0%,
        var(--background) 100%
      );
    }
  }

  video {
    opacity: 0.8;
    height: 100%;
    width: 100%;
    object-fit: cover;
    margin: auto;
    position: relative;
  }

  @media (max-width: 750px) {
    video {
      height: 50%;
    }
  }
`;

export default NewTrait