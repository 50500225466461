import React from "react";

import styled from "styled-components";

import { MainContentMaxWidth } from "components/Layouts/MaxWidth";
import { H2, P } from "components/Typography";
import { BackgroundImage, FlexParent } from "components/Layouts/Utils";

const MicronDrones = ({  subtitle, textHTML, background, image }) => {
  return (
    <Section>
      <BackgroundImage 
        loading="lazy"
        width={800}
        height={450}
        src={background.url} />

      <MainContentMaxWidth
        style={{
          zIndex: "6",
          position: "relative",
        }}
      >
        <FlexParent>
          <img 
          
          loading="lazy"
          width={1230}
          height={1072}
          src={image.url} alt="Micron Drones" />
          
          <TextGroup>
            <H2
              style={{ fontSize: "2.2rem", marginBottom: "2rem" }}
              color="white"
            >
              {subtitle}
            </H2>
            <P>
              <span dangerouslySetInnerHTML={{ __html: textHTML }} />
            </P>
          </TextGroup>
        </FlexParent>
      </MainContentMaxWidth>
    </Section>
  );
};

const Section = styled.div`
  position: relative;
  overflow: hidden;

  &::after {
    content: " ";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to bottom, rgba(12, 6, 30, 0) 0%, #014FC4 100%);
  }

  ${MainContentMaxWidth} {
    padding: 6rem 0;

      @media (max-width: 700px) {
        padding: 0 2rem;
      }
  }

  ${BackgroundImage} {
    opacity: 1;
  }

  ${FlexParent} {
    img {
      width: 50%;

      @media (max-width: 700px) {
        width: 100%;
        margin-bottom: 2rem;
      }
    }
  }
`;

const TextGroup = styled.div`
  max-width: 35rem;
  padding: 4rem;
  position: relative;
  z-index: 6;

  @media (max-width: 700px) {
    padding: 0rem;
    max-width:unset;
  }
`;

export default MicronDrones;
