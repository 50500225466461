export const data = {
  // change to true to display each section
  displayVideo: true,
  videoLink: "https://www.youtube.com/embed/6Ru5KtBjSXk?controls=0&autoplay=1",
  videoThumbnail: "trailer-clash.jpg",
  displayCards: true,
  cardsLink: "https://play.skyweaver.net/market/cards/buy?ownership=ALL&itemType=SW_SILVER_CARDS&set=Clash%20of%20Inventors&sorting=MANA_ASCENDING",
  calendar: {
    title: "CARD REVEAL CALENDAR",
    textHTML:
      "<p>Members of our <a style='color: var(--lilac)' target='_blank' href='/community/creators-program'>Creators program</a> will reveal new cards until Clash of Inventors gets released. Click ot access their channels and stay tuned.</span>",
    dates: [
      {
        date: "Jun 20",
        username: "Mephisto12",
        platform: "Twitch",
        filename: "mephisto13-final.png",
        link: "https://twitter.com/SkyweaverGame/status/1539314770502746120",
        text: "revealed 5 cards on Twitch <br /> At 17:00 CDT",
        revealed: true,
      },
      {
        date: "Jun 21",
        username: "Gaara",
        platform: "Twitch",
        filename: "gaara-final.png",
        link: "https://twitter.com/SkyweaverGame/status/1539377662887702530",
        text: "revealed 5 cards on Twitch <br /> At 18:00 CEST",
        revealed: true,
      },
      {
        date: "Jun 22",
        username: "OG_GoZu",
        platform: "Twitch",
        filename: "og_gozu-final.png",
        link: "https://twitch.tv/og_gozu",
        text: "reveals 5 cards on Twitch <br /> At 14:00 EST",
        revealed: true,
      },
      {
        date: "Jun 23",
        username: "Missaurus",
        platform: "Twitch",
        filename: "missaurus-final.png",
        link: "https://twitter.com/SkyweaverGame/status/1540259113187397632",
        text: "reveals 5 cards on Twitch <br /> At 20:00 EST",
        revealed: true,
      },

      {
        date: "Jun 25",
        username: "Moonlight",
        platform: "Twitch",
        filename: "moonlight-final.png",
        link: "https://twitter.com/SkyweaverGame/status/1540812100611743751?s=20&t=Aqd4ig5GbbzMgMBsOJzSPA",
        text: "reveals 5 cards on Twitch <br /> At 09:30 CEST",
        revealed: true,
      },
      {
        date: "Jun 26",
        username: "Echeverria",
        platform: "Twitch",
        filename: "echeverria-final.png",
        link: "https://twitter.com/SkyweaverGame/status/1541162854858035202?s=20&t=Aqd4ig5GbbzMgMBsOJzSPA",
        text: "reveals 5 cards on Twitch <br /> At 18:30 CEST",
        revealed: true,
      },
      {
        date: "Jun 27",
        username: "Bumble",
        platform: "Youtube",
        filename: "bumble-final.png",
        link: "https://twitter.com/SkyweaverGame/status/1541439089693675524?s=20&t=Aqd4ig5GbbzMgMBsOJzSPA",
        text: "reveals 5 cards on Youtube <br /> At 11:00 CEST",
        revealed: true,
      },
 
      {
        date: "Jun 29",
        username: "JontheNerd",
        platform: "Twitch",
        filename: "jonthenerd-final.png",
        link: "https://twitter.com/SkyweaverGame/status/1542452369199403008?s=20&t=Aqd4ig5GbbzMgMBsOJzSPA",
        text: "reveals 5 cards on Twitch <br /> At 21:00 MST",
        revealed: true,
      },
    ],
  },
};
