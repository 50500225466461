import React, { Component } from "react";

import "css/main.css";
import "css/fonts.css";

import { data } from "data/expansion-pack"

import Seo from "components/SeoHead";
import ClashOfInvestors from "components/ClashOfInventors";

const seoData = {
    title: `Skyweaver - A Trading Card Game from Another Dimension`,
    url: `https://www.skyweaver.net`,
    desc: `A Worldwide Competitive Trading Card Game. Free to Play from Phone, Tablet & PC. Battle, Win, Own, Trade. Easy to Learn, Strategic, and Incredibly Fun. Play Now.`,
    img: `https://skyweaver.net/images/clash-of-inventors/social.jpg
  `
};

const Page = () => (
  <>
    <Seo {...seoData} />
    <ClashOfInvestors  {...data} />
  </>
)

export default Page
