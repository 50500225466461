import React from "react";
import styled from "styled-components";

import { H2, P } from "components/Typography"
import { SmallMaxWidth } from "components/Layouts/MaxWidth";
import { BackgroundImage } from "components/Layouts/Utils";

const Join = ({
  title,
  text,
  button,
  image
}) => {
  return (
    <Section>
      <BackgroundImage src={image.url} />
      
      <SmallMaxWidth>
        <H2 style={{
          color: "var(--background)"
        }}>{title}</H2>
        <P color="black">{text}</P>

        <a href="https://play.skyweaver.net" target="_blank" rel="noopener noreferrer">
          <img src={"/images/clash-of-inventors/play-now.png"} />
        </a>
      </SmallMaxWidth>
    </Section>
  )
}

const Section = styled.section`
  position: relative;
  margin-bottom: -2rem;
  overflow: hidden;
  padding: 2rem;

  &::after {
    content: " ";
    position: absolute;
    bottom: -2rem;
    left: 0;
    width: 100%;
    height: 30%;
    background: linear-gradient(
      to bottom,
      rgba(12, 6, 30, 0) 0%,
      var(--background) 100%
    );
  }

  ${BackgroundImage} {
    opacity: 0.99;
  }

  ${SmallMaxWidth} {
    padding: 22rem 0;
    padding-bottom: 24rem;
    text-align: center;

    a {
      display: block;
      text-align: center;
      max-width: 800px;
      margin: auto;
      margin-top: -80px;
      transition: 0.3s ease-out opacity;

      &:hover {opacity: 0.8}
    }
    img {
      height: 100%;
      object-fit: cover;
      width: 100%;
    }

    @media (max-width: 700px) {
      padding: 10rem 2rem;
      a {margin-top:-50px}
    }
  }

`;

export default Join