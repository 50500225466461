import React, { useState, useEffect } from "react";
import styled from "styled-components";

import { MedMaxWidth } from "components/Layouts/MaxWidth";
import { P } from "components/Typography";
import EmbededPlayer from "components/SectionLanding/EmbededPlayer";
import { BackgroundImage } from "components/Layouts/Utils";

import { data } from "data/expansion-pack/cms"

const StyledLanding = styled.section`
  position: relative;
  width: 100%;
  background-color: var(--background);

`;

const BackgroundAsset = styled.figure`
  width: 100%;
  height: 50rem;
  position: relative;

  &::after {
    content: " ";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 60%;
    background: linear-gradient(180deg, rgba(12, 6, 30, 0) 0%, #004ec4 100%);
    opacity: ${({ opacity }) => (opacity ? opacity : 1)};
  }

  .background-landing {
    object-fit: cover;
    top: 6.2rem;
    left: 0;
    height: 100%;
    width: 100%;
  }

  .mobile-img {
    display: none;
  }

  @media (max-width: 750px) {
    .mobile-img {
      display: block;
      width: 100%;
      margin-bottom: -30%;
    }

    .background-landing {
      display: none;
    }

    height: auto;
    background: #004ec4;
  }
`;

const Div = styled.div`
  position: relative;
  overflow: hidden;
`

const TextGroup = styled.div`
  padding: 4rem;
  padding-bottom: 8rem;
  text-align: center;
  position: relative;
  z-index:4;

  @media (max-width: 750px) {
    padding: 0rem;
    padding-bottom: 4rem;
  }

`

const LogoGroup = styled.div`
  position: absolute;
  bottom: -25%;
  max-width: 42rem;
  width: 100%;
  pointer-events: none;
  left: 50%;
  transform: translateX(-50%);
  z-index: 10;

  img {
    width: 100%;
  }

  @media (max-width: 750px) {
    position: relative;
    transform: none;
    width: 70%;
    left: 0%;
    margin: auto;
    margin-bottom: -15%;
  }
`;


const Landing = ({ title, textHTML, image, background }) => {
  const [opacity, setOpacity] = useState(1)

  useEffect(() => {
    const threshold = 500;


    const handleScroll = () => {
      setOpacity(-(window.scrollY / threshold) + 1);
    }

    window.addEventListener('scroll', handleScroll, true)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])
  
  
  return (
    <StyledLanding>
      <BackgroundAsset>
        <picture>
          <img
            style={{
              opacity,
            }}
            height={607}
            width={1080}
            loading="lazy"
            className="background-landing"
            src={image.url}
            alt="Clash of Inventors"
          />
        </picture>

        <img
          className="mobile-img"
          src={"/images/clash-of-inventors/arena-mobile.jpg"}
        />

        <LogoGroup>
          <picture>
            <img
              loading="lazy"
              height={1080}
              width={1080}
              src={`/images/clash-of-inventors/clash-of-inventors.png`}
              alt="Clash of Inventors"
            />
          </picture>
        </LogoGroup>
      </BackgroundAsset>

      <Div>
        <BackgroundImage
          height={2500}
          width={1406}
          loading="lazy"
          src={background.url}
        />

        <MedMaxWidth>
          <TextGroup>
            {data.displayVideo && (
              <>
                <EmbededPlayer thumbnail={"/images/clash-of-inventors/"+data.videoThumbnail} src={data.videoLink} />
                <br />
              </>
            )}

            <P color="white">
              <div dangerouslySetInnerHTML={{ __html: textHTML }} />
            </P>
          </TextGroup>
        </MedMaxWidth>
      </Div>
    </StyledLanding>
  );
};

export default Landing;
