import React from 'react'

import styled from 'styled-components'

import BackgroundContainer from 'components/HowToPlay/BackgroundContainer'
import Carousel from 'components/HowToPlay/Carousel'
import { GenericVideoBlock } from 'components/HowToPlay/Carousel'
import { BackgroundImage } from 'components/Layouts/Utils'

const Subthemes = props => {
  return (
    <Section sectionColor={props.sectionColor}>
      {props.background && <BackgroundImage src={props.background.url} />}
      <Group>
        <div className="parent">
          <BackgroundContainer {...props}>
            <Carousel
              {...props}
              type={'effects'}
              content={props.items.map((item, i) => (
                <GenericVideoBlock key={i} {...item} />
              ))}
            />
          </BackgroundContainer>
        </div>
      </Group>
    </Section>
  )
}

const Group = styled.div`
  width: calc(100% - 2rem);
  max-width: 900px;
  margin: auto;
  position: relative;

  .parent {
    position: relative;
    z-index: 10;
  }

  .styled-img {
    position: absolute;
    top: 50%;
    width: 300px;
    z-index: 0;
  }
`

const Section = styled.div`
  padding: 4rem 0;
  position: relative;
  width: 100%;
  overflow: hidden;
`

export default Subthemes
