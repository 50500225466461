import React from "react"

import styled from "styled-components"

import Layout from "components/Blog/Layout"

import { data } from "data/expansion-pack/cms"

import Landing from "./Landing"
import NewTrait from "./NewTrait"
import MicronDrones from "./MicronDrones"
import Subthemes from "./Subthemes"
import Expansion from "./Expansion"
import Join from "./Join"
import Calendar from "./Calendar"

const ClashOfInventors = (props) => {
  const  { landing, newTrait,  drones, subthemes, expansion, join,  } = props
  
  return (
    <Layout enforceBackground>
      <Landing {...landing} />
      <Section>
        <NewTrait {...newTrait} />
        <MicronDrones {...drones} />
        <Subthemes {...subthemes} />
        <Calendar />
        {data.displayCards && <Expansion {...expansion} />}
        <Join {...join} />
      </Section>
    </Layout>
  );
}

const Section = styled.div`
  background-color: var(--background);
  width: 100%;
  position: relative;
`

export default ClashOfInventors
